import styled, { css } from "styled-components"

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 5rem auto;
  overflow: auto;
`

export const Container = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 360px;

  &::before {
    content: "Photo by Andrew Neel";
    font-size: 0.75rem;
    position: absolute;
    bottom: -1.25rem;
    right: 0.5rem;
    color: rgba(0, 0, 0, 0.4);
  }
`

export const HeaderLinks = styled.div`
  flex-shrink: 0;

  @media (max-width: 800px) {
    padding-left: 0.5rem;
  }
`

export const Align = styled.div`
  flex-shrink: 0;
  height: 200px;
  padding-top: 2rem;
  padding-right: 5rem;
  text-align: right;
  position: relative;
`

export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3.5rem;
  text-shadow: 1px 1px 3px white;
`

export const Subtitle = styled.h2`
  font-size: 0.75rem;
  margin-top: 1.25rem;
  text-shadow: 1px 1px 3px white;
  position: absolute;
  top: 0.75rem;
  right: 3rem;
  opacity: 0.4;
`

export const Pages = styled.div`
  padding: 2rem;
`

export const PagesTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 3rem;

  &::first-letter {
    font-size: 2.5rem;
    font-weight: bolder;
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`

const Link = styled.a`
  font-size: 1.25rem;
  line-height: 3;
  color: black;
  margin-right: 2rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const NavLink = styled(Link)`
  font-size: 0.875rem;
`

export const PoemLink = styled(Link)`
  display: flex;
  align-items: baseline;
  transition: 0.2s;

  &:hover {
    text-decoration: none;
    transform: translateX(5px);
  }
`

export const Dotted = styled.div`
  flex-grow: 1;
  border-bottom: 2px dotted rgba(0, 0, 0, 0.4);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

export const Delim = styled.span`
  display: inline-block;
  margin-right: 1rem;
  text-shadow: 3px 3px 2px rgba(74, 39, 28, 0.2);
`
