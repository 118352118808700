import React from "react"

import { PoemWithSlug } from "../types/poemTypes"
import { useScrollToTop } from "../hooks/useScrollToTop"
import { Layout } from "../components/Layout"
import { HomeContent } from "../components/HomeContent"

type Props = {
  pageContext: {
    poems: PoemWithSlug[]
  }
}

const Index: React.FC<Props> = ({ pageContext }) => {
  const { poems } = pageContext

  useScrollToTop()
  
  if (!poems) return null
  
  return (
    <Layout title="Muetry.">
      <HomeContent poems={poems} />
    </Layout>
  )
}

export default Index
