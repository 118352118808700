import React, { CSSProperties } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { PoemWithSlug } from "../types/poemTypes"
import {
  Layout,
  Container,
  Title,
  Subtitle,
  Align,
  Pages,
  PagesTitle,
  NavLink,
  PoemLink,
  Links,
  Dotted,
  Delim,
  HeaderLinks,
} from "./HomeContent.style"

const imgStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderBottomLeftRadius: "2px",
  borderBottomRightRadius: "2px",
} as CSSProperties

type Props = {
  poems: PoemWithSlug[]
}

export const HomeContent: React.FC<Props> = ({ poems }) => (
  <Layout>
    <Align>
      <Title>Muetry.</Title>
      <Subtitle>The poetry of James Formica</Subtitle>
    </Align>

    <HeaderLinks>
      <NavLink href="#poems">Poems</NavLink>
      <NavLink href="/kaleidoscope">The Kaleidoscope</NavLink>
      <NavLink href="/poi">POI</NavLink>
    </HeaderLinks>

    <Container>
      <StaticImage
        src="../images/andrew-neel-book-thin.jpg"
        alt="A dinosaur"
        placeholder="tracedSVG"
        objectFit="cover"
        loading="lazy"
        width={2000}
        style={imgStyles}
      />
    </Container>

    <Pages>
      <PagesTitle id="poems">For your perusal...</PagesTitle>
      <Links>
        {poems.map((p) => (
          <PoemLink key={p.slug} href={p.slug}>
            <Delim>○</Delim>
            {p.poem.attributes.title}
            <Dotted />
            {p.number}
          </PoemLink>
        ))}
      </Links>
    </Pages>
  </Layout>
)
